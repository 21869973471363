.btn {
  width: 90%;
  max-width: 360px;
  color: $color-white;
  font-size: 1rem;
  padding: 0.6rem 1rem;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  &.btn_primary {
    background-color: $color-blue;
    margin-top: 1.6rem;

    &.gray {
      background-color: #808080;
    }
  }

  &.btn_secondary {
    background-color: #808080;
  }

  svg {
    margin: -6px 8px -8px -1.5rem;
    padding: 0;
    width: 1.8rem;
    height: 1.8rem;
  }

  @media screen and (max-width: 520px) {
    max-width: none;
    padding: 0.8rem 1rem;
    font-size: 1.2rem;
  }
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 360px;
  padding: 1rem 0.1rem;
  border-radius: 8px;
  background-color: $color-white;

  h3 {
    font-size: 1.2rem;
    margin: 0 0 2rem;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 8px 10px;
    font-size: 0.9rem;
    transition: all 0.3s;
    color: $color-text !important;
    background-color: $color-body;
    border-radius: 8px;
    border: none;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $color-text !important;
      text-fill-color: $color-text !important;
      -webkit-box-shadow: 0 0 0px 50px $color-body inset;
      box-shadow: 0 0 0px 50px $color-body inset;
      caret-color: $color-text !important;
      border: none;
      border-radius: 8px !important;
    }

    &::-webkit-input-placeholder {
      color: $color-additional;
    }
    &::-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 19+ */
    &:-moz-placeholder {
      color: $color-additional;
    }
    /* Firefox 18- */
    &:-ms-input-placeholder {
      color: $color-additional;
    }

    &[disabled] {
      background-color: $color-dusty-white;

      &:focus,
      &:active {
        border-color: $color-gray;
      }
    }

    &:focus,
    &:active {
      outline: none;
      border-color: $color-border;
    }
  }

  select {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  textarea {
    resize: none;
    height: 60px;
  }

  fieldset {
    position: relative;
    width: 70%;
    margin: 2rem auto;
    border-radius: 12px;
    border: 1px solid $color-border-fieldset;
  }

  legend {
    padding: 0 8px;
    color: $color-text;
  }

  svg {
    &.close {
      position: absolute;
      top: -8px;
      right: 4px;
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 520px) {
    h3 {
      font-size: 1.3rem;
    }

    input,
    textarea {
      padding: 10px 12px !important;
      font-size: 1.1rem !important;
    }

    fieldset {
      width: 100%;
    }

    svg {
      &.close {
        width: 1.9rem;
        height: 1.9rem;
      }
    }

    .form_group-label {
      font-size: 1.1rem !important;
    }
  }
}

.form_group {
  position: relative;
  width: 100%;
  margin: 0 0 20px;

  &.unable {
    opacity: 0.5;
    cursor: not-allowed;

    input {
      pointer-events: none;
    }
  }

  .p_message {
    margin: 0.3rem 1.8rem 0 6rem;
    font-size: 0.75rem;
    line-height: 1;
    color: $color-blue;
    text-align: right;
  }
}

.form_group-field {
  position: relative;
  width: 90%;
  display: flex;
  margin: auto;
  color: $color-text !important;
  background-color: $color-dusty-white;
  border: 1px solid $color-border-fieldset;

  &.input_radius {
    border-radius: 8px;

    .input {
      width: 100%;
    }

    .form_group-button {
      position: absolute;
      top: 8px;
      right: 4px;
      z-index: 1;
      border: none;
      background: none;
    }
  }
}

.input {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  input[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  svg {
    position: absolute;
    right: 0;
    width: 2rem;
    height: 2rem;
    padding: 6px !important;
    margin: 0 !important;
    cursor: pointer;
    transition: all 200ms;
    color: $color-text;

    &.no_event {
      pointer-events: none !important;
    }

    &:active {
      transform: scale(0.9);
    }
  }

  //   &.with_icon_right {
  //     input {
  //       //   padding-right: 28px;
  //       padding-right: 1.6rem !important;
  //     }
  //   }

  @media screen and (max-width: 520px) {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.login {
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: block;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .login__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: max-content;
    min-height: 100vh;

    padding: 2rem 0;
    background-color: $color-green;

    .p_version {
      margin-top: 2rem;
      font-size: 0.7rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .login__head {
    width: 100%;
    margin: 0 0 3rem;
    padding: 0;

    .login__head_logo {
      width: 70%;
      height: 160px;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url(../images/logo/logo-genial_720-blanco.png);
    }

    .login__head_title {
      margin: 0 0 32px;
      font-size: 0.9rem;
      line-height: 16px;
      white-space: pre-line;
      color: $color-white;
      text-align: center;
    }
  }
}

@media screen and (max-width: 520px) {
  .login {
    .login__head {
      margin: 1rem 0 3rem;
    }
  }
}
