.qr-reader {
  width: 360px;
  height: 380px;
  margin: 0;

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-color: black;
  }

  &.rotate {
    transform: scaleX(-1);
  }

  /* Media Queries for mobile screens */
  @media (max-width: 426px) {
    width: 100%;
    margin: 2rem 0 3rem;
  }
}
