@charset "utf-8";

@font-face {
  font-family: "GTPressuraBold";
  src: url(../fonts/GT-Pressura/GT-Pressura-Bold.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GTPressuraLight";
  src: url(../fonts/GT-Pressura/GT-Pressura-Light.ttf) format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "GTPressuraRegular";
  src: url(../fonts/GT-Pressura/GT-Pressura-Regular.ttf) format("truetype");
  font-display: swap;
}
