header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  background-color: $color-green;
  color: $color-text;
  z-index: 100;

  .menu_logo {
    position: relative;
    width: 140px;
    height: 70px;
    background-repeat: no-repeat;
    background-size: 130px;
    background-position: center;
    background-image: url(../images/logo/logo-genial_720-blanco.png);
  }

  button._close {
    position: absolute;
    right: 0;
    top: 0;
    width: 70px;
    height: 70px;
    background-color: transparent;
    border: none;
    color: $color-white;

    svg {
      padding: 12px;
      width: 70px;
      height: 70px;
      cursor: pointer;
    }
  }


  @media screen and (max-width: 520px) {
    height: 60px;

    .menu_logo {
      width: 130px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: 120px;
      background-position: center;
    }

    button._close {
      width: 60px;
      height: 60px;

      svg {
        width: 60px;
        height: 60px;
      }
    }
  }
}

.wrapper {
  display: block;
  position: relative;
  top: 0;
  width: 100%;
  min-height: 100vh;
  padding: 90px 12px 16px;
  color: $color-text;
  background-color: $color-white;

  .container,
  .container_error {
    max-width: 1280px;
    margin: 0 auto;
    transition: all 0.3s;
    border-radius: 16px;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 85vh;
    padding: 2rem 1rem;

    .main_title {
      font-size: 1.2rem;
      text-transform: capitalize;
      text-align: center;
    }

    .main_message {
      font-size: 1.3rem;
      text-align: center;
      font-weight: normal;
    }

    .card_state {
      padding: 1.2rem 1rem;
      margin: 4rem 0 3rem;
      border-radius: 8px;

      h1 {
        font-size: 1.2rem;
        text-transform: uppercase;
        text-align: center;
        color: rgb(255, 255, 255);
        font-weight: normal;
        text-shadow: 0 0 2px rgb(0, 0, 0), 1px 1px 2px rgba(0, 0, 0, 0.8);

        strong {
          font-weight: bold;
          font-size: 1.3rem;
        }
      }

      &.yellow {
        background-color: #ffd93f;
      }

      &.green {
        background-color: #438014;
      }

      &.gray {
        height: 70vh;
        margin: 1rem 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background-color: #ebebeb;

        h1 {
          margin-top: 1rem;
          color: $color-text;
          text-shadow: none;
          text-transform: none;
        }

        .btn_secondary {
          position: absolute;
          bottom: 7.6rem;
        }
      }
    }

    .btn_primary {
      position: absolute;
      bottom: 3rem;
    }

    .btn_error {
      background-color: red;
      position: absolute;
      bottom: 7.6rem;
    }

    .p_version {
      position: absolute;
      bottom: 0;
      font-size: 0.7rem;
      color: rgba($color-text, 0.7);
    }

    @media screen and (max-width: 520px) {
      flex-direction: column;
      padding: 1rem 14px;
    }
  }

  .container_error {
    display: flex;
    flex-flow: row nowrap;
    padding: 3rem 2rem;

    p {
      width: 50%;
      padding: 6rem 4rem 0 0;

      span {
        display: block;
        font-size: 1.2rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }
    }

    img {
      width: 50%;
    }

    @media screen and (min-width: 1024px) {
      padding: 3rem 6rem;
    }

    @media screen and (max-width: 620px) {
      padding: 0 1rem 2rem;
      flex-wrap: wrap-reverse;

      p {
        width: 100%;
        margin-top: -2rem;
        padding: 0 1rem 0;
        font-size: 1.2rem;

        span {
          margin-top: 2rem;
          margin-bottom: 2.2rem;
          font-size: 1.5rem;
        }

        a {
          font-size: 1.2rem;
        }
      }

      img {
        width: 100%;
      }
    }
  }
}

.splash {
  display: block;
  background-color: $color-green;

  .splash_img {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100vh;

    img {
      width: 70%;
      max-width: 280px;
      margin: 0 auto;
    }
  }
}

footer {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  color: $color-text;
  background-color: $color-white;

  .footer {
    padding: 0 20px 20px;

    p {
      font-size: 0.9rem;
      text-align: center;
    }

    i {
      font-size: 0.7rem;
      opacity: 0.5;
    }
  }
}
